/* This push is only meant to be reached from our page <head> tag, so it only runs once per application load */
const cookies = {
	marketing: window.localStorage['necessaryConsent'] == undefined ? false : true,
	necessary: window.localStorage['marketingConsent'] == undefined ? false : JSON.parse(window.localStorage['marketingConsent']),
	personalisation: window.localStorage['personalisationConsent'] == undefined ? false : JSON.parse(window.localStorage['personalisationConsent']),
};

const isLoggedIn = window.localStorage['isLoggedIn'] == undefined ? false : JSON.parse(window.localStorage['isLoggedIn']);

(function(isLoggedIn, cookies) {
	dataLayer.push({
		'user_info': {
			'is_logged_in': isLoggedIn,
		},
		'cookie_info': {
			'marketing_consent': cookies.marketing,
			'personalisation_consent': cookies.personalisation,
		}
	});
})(isLoggedIn, cookies);
